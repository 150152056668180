<template>
  <v-app>
    <v-container class="my-6 pa-0" fluid>
      <v-card class="py-0">
        <div class="card-header-padding pb-0">
          <v-row class="mb-0">
            <v-col lg="8" sm="8" md="8" class="pb-0">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Үнэлгээ 2 -
                  <span v-if="selectedProgram" @click="_print2" class="blue--text">{{
                    selectedProgram["STUDENT_GROUP_NAME"]
                  }}</span>
                </h5>
                <p class="text-typo">
                  Нийт xичээлийн тоо:
                  <span class="blue--text" v-if="filteredCourses">{{
                    filteredCourses.length
                  }}</span>
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding pt-0">
          <v-progress-linear
            v-if="loading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-data-table
            class="scoreTables"
            v-if="courses"
            hide-default-footer
            hide-default-header
            :items-per-page="-1"
            :items="filteredCourses"
          >
            <template slot="header">
              <tr style="font-size: 13px">
                <th class="text-start font-weight-normal">No</th>
                <th class="text-start font-weight-normal">Ээлжит хичээл</th>
                <th class="text-start font-weight-normal">Багш</th>
                <th class="text-start font-weight-normal">Төрөл</th>
                <th class="text-start font-weight-normal">С.Т</th>
                <th class="pl-0">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-start font-weight-normal"
                      style="
                        border-bottom: 2px solid #dddddd;
                        padding-left: 5px;
                      "
                      >Баталгаажсан</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="border-right: 2px solid #dddddd; padding-left: 5px"
                      >Хувь</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="padding-left: 5px"
                      >Тоо</v-col
                    >
                  </v-row>
                </th>
                <th class="text-center font-weight-normal">Хянагдаж байгаа</th>
                <th class="text-center font-weight-normal">Цуцлагдсан</th>
                <th class="text-center font-weight-normal">Бүртгэсэн</th>
                <th class="text-center font-weight-normal">Үнэлгээний дундаж</th>
                <th>Үйлдэл</th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td @click="_print(item)">{{ item.courseIndex }}</td>
                <td class="text-start px-1">
                  {{ item.SUBJECT_AREA_NAME }}
                </td>
                <td v-if="item.eeljitLesson" class="px-1">
                  <template v-if="item && item.eeljitLesson">
                    <small class="blue--text"
                      v-for="(teacher, teacherIndex) in item.eeljitLesson
                        .byTeachers"
                      :key="teacherIndex"
                      ><span v-if="teacherIndex > 0">, </span
                      >{{ teacher.teacherDisplayName }}</small
                    >
                  </template>
                </td>
                <td v-else>-</td>
                <td v-if="item.ENROLLMENT_CATEGORY_NAME" class="px-2">
                  <small>{{ item.ENROLLMENT_CATEGORY_NAME.replace("судлах", "") }}</small>
                </td>
                <td v-else>-</td>
                <td style="background-color: #ffebee;" v-if="item.eeljitLesson&&item.eeljitLesson.numberOfStudents<=1" class="text-center blue--text px-2">
                  {{ item.eeljitLesson.numberOfStudents }}
                </td>
                <td v-else-if="item.eeljitLesson&&item.eeljitLesson.numberOfStudents>1" class="text-center blue--text px-2">
                  {{ item.eeljitLesson.numberOfStudents }}
                </td>
                <td v-else>-</td>
                <td class="pl-0">
                  <v-row no-gutters style="height: 100% !important">
                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="
                        border-right: 2px solid #dddddd;
                        padding-left: 5px;
                        color: green;
                      "
                      >{{
                        (
                          ((item.eeljitLesson.year1UnelgeeScores
                            ? item.eeljitLesson.year1UnelgeeScores
                                .approvedNumber
                            : 0) /
                            item.eeljitLesson.numberOfStudents) *
                          100
                        ).toFixed(2) > 0
                          ? (
                              ((item.eeljitLesson.year1UnelgeeScores
                                ? item.eeljitLesson.year1UnelgeeScores
                                    .approvedNumber
                                : 0) /
                                item.eeljitLesson.numberOfStudents) *
                              100
                            ).toFixed(2)
                          : 0
                      }}%</v-col
                    >

                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="padding-left: 5px; color: green"
                      >{{
                        item.eeljitLesson.year1UnelgeeScores
                          ? item.eeljitLesson.year1UnelgeeScores.approvedNumber
                          : 0
                      }}</v-col
                    >
                  </v-row>
                </td>
                <td style="color: #db9943" class="text-center">
                  {{
                    item.eeljitLesson.year1UnelgeeScores
                      ? item.eeljitLesson.year1UnelgeeScores.inProgressNumber
                      : 0
                  }}
                </td>
                <td style="color: red" class="text-center">
                  {{
                    item.eeljitLesson.year1UnelgeeScores
                      ? item.eeljitLesson.year1UnelgeeScores.rejectedNumber
                      : 0
                  }}
                </td>
                <td style="color: purple" class="text-center">
                  {{
                    item.eeljitLesson.year1UnelgeeScores
                      ? item.eeljitLesson.year1UnelgeeScores.sentNumber
                      : 0
                  }}
                </td>
                <td  class="text-center">
                  {{
                    item.eeljitLesson.year1UnelgeeScores
                      ? item.eeljitLesson.year1UnelgeeScores.scoreAverage > 0
                        ? item.eeljitLesson.year1UnelgeeScores.scoreAverage
                        : 0
                      : 0
                  }}%
                </td>
                <td>
                  <v-btn
                    dark
                    elevation="0"
                    x-small
                    class="bg-gradient-danger text-capitalize mx-2"
                    @click="_doUnelgee(item.eeljitLesson)"
                  >
                    баталгаaжуулах
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-progress-linear
            v-else-if="loading == false"
            indeterminate
            color="red"
          ></v-progress-linear>
        </div>
      </v-card>
    </v-container>
    <v-dialog
      v-model="unelgeeDialog"
      height="500"
      width="1400"
      v-if="currentSelectedLesson && renderComponent"
      persistent
      scrollable
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row>
            <v-col >
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ userData.school.currentYear }}
                <span
                class="blue--text ml-4"
                v-if="currentSelectedLesson"
                @click="_print3"
              >
                {{ currentSelectedLesson.courseInfo.COURSE_NAME }}/
                <span
                  v-if="
                    currentSelectedLesson.classGroups &&
                    currentSelectedLesson.classGroups.length > 0
                  "
                >
                  {{ currentSelectedLesson.classGroups[0].classGroupFullName }}
                </span>
              </span>
              </h5>
             
            </v-col>
            <v-spacer></v-spacer>
            <!-- <v-col lg="3" md="3" sm="3" cols="3">
              <v-select
                v-model="selectedGradingMethod"
                label="Бүлэг сэдвийн үнэлгээ (Үнэлгээ-I)"
                item-text="name"
                item-value="value"
                :items="gradingMethods"
              >
              </v-select>
            </v-col> -->
            <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="ml-2 my-0 py-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн (' + simpleCalculationModel + ')'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee2DialogManager
          class="pt-0"
          v-if="allScores"
          v-bind="$attrs"
          :students="students"
          :allScores="allScores"
          :simpleCalculationModel="simpleCalculationModel"
          :lesson="currentSelectedLesson"
          :selectedGradingMethod="selectedGradingMethod"
        >
        </ScoreInputUnelgee2DialogManager>
        <v-card-text
          class="mx-10 py-10 mt-4 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block: !important;" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-4" v-else>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_saveUnelgee1OfStudents"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ScoreInputUnelgee2DialogManager from "./ScoreInputUnelgee2DialogManager";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    ScoreInputUnelgee2DialogManager,
  },
  data: () => ({
    currentSelectedSemester: 2,
    coursesAdditional: null,
    school: null,
    showMyClassLesson: true,
    students: null,
    selectedProgram: null,
    teachers: null,
    selectedType: { name: "Заавал судлаx", value: 1, kValue: "MANDATORY" },
    courses: null,
    loading: false,
    selectedPlan: null,
    currentSelectedLesson: null,
    renderComponent: true,
    unelgeeDialog: false,
    savingUnelgee: false,
    simpleCalculationModel: true,
    allScores: null,
    selectedGradingMethod: 2,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр шалгалтын дүнгээр",
        value: 1,
      },
      {
        name: "Сүүлийн шалгалтын дүнгээр",
        value: 2,
      },
    ],
  }),

  props: {
    zClassGroupRefPath: {
      type: String,
    },
    refresh: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredCourses() {
      var list = [];
      var counter = 0;
      if (this.courses)
        if (this.showMyClassLesson) {
          for (const item of this.filteredLessons) {
            if (
              item.eeljitLesson &&
              item.eeljitLesson.byTeachers &&
              item.eeljitLesson.byTeachers.length > 0
            ) {
              counter++;
              item.courseIndex = counter;
              list.push(item);
            }
          }
          return list;
        } else {
          for (const item of this.filteredLessons) {
            counter++;
            item.courseIndex = counter;
            list.push(item);
          }
          return list;
        }
      else return [];
    },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.courses) {
        for (var course of this.courses) {
          if (this.showMyClassLesson)
            if (
              course.eeljitLesson &&
              course.eeljitLesson.byTeachers &&
              course.eeljitLesson.byTeachers.length > 0
            )
              if (
                course.eeljitLesson.esisLessonType &&
                course.eeljitLesson.esisLessonType.esisLessonTypeId > 1 &&
                course.eeljitLesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
              ) {
                var parentLL = null;
                const cc = list.find(
                  (lesson) =>
                    lesson.isCollected &&
                    lesson.courseInfo.COURSE_ID &&
                    lesson.courseInfo.COURSE_ID ==
                      course.eeljitLesson.courseInfo.COURSE_ID
                );
                if (cc == undefined) {
                  counter++;
                  parentLL = course;
                  parentLL.eeljitLesson.index = counter;
                  parentLL.eeljitLesson.uniqueId =
                    course.eeljitLesson.id + counter;
                  parentLL.eeljitLesson.name3 =
                    counter + ". " + course.eeljitLesson.name2;
                  parentLL.eeljitLesson.subLessons = [course.eeljitLesson];
                  console.log(course.eeljitLesson);
                  parentLL.eeljitLesson.isCollected = true;
                  list.push(parentLL);
                } else {
                  cc.subLessons.push(course.eeljitLesson);
                }
              } else {
                counter++;
                course.eeljitLesson.index = counter;
                course.eeljitLesson.uniqueId = course.eeljitLesson.id + counter;
                course.eeljitLesson.name3 =
                  counter + ". " + course.eeljitLesson.name2;
                list.push(course);
              }
        }
      }
      return list;
    },
  },
  async created() {
    if (!this.school) {
      this.school = this.userData.school;
    }
    if (this.refresh) {
      location.reload();
    }
    await fb.db
      .doc(this.zClassGroupRefPath)
      .get()
      .then((doc) => {
        let prog = doc.data();
        prog.ref = doc.ref;
        prog.id = doc.id;
        this.selectedProgram = prog;
      });
    if (this.selectedProgram && this.selectedProgram.studyProgramRef) {
      this.selectedProgram.studyProgramRef.get().then((doc) => {
        let plan = doc.data();
        plan.id = doc.id;
        plan.ref = doc.ref;
        this.selectedPlan = plan;
        this.readPlan();
        this.readAdditionalLessons();
      });
    }

    this.school.ref
      .collection("teachers")
      .where("role", "==", "teacher")
      .where("deleted", "==", false)
      .orderBy("firstName", "asc")
      .get()
      .then((docs) => {
        this.teachers = [];
        var counter = 0;
        docs.docs.forEach((doc) => {
          counter++;
          let teacher = doc.data();
          teacher.ref = doc.ref;
          teacher.id = doc.id;
          teacher["name2"] = counter + ". " + teacher["DISPLAY_NAME"];
          this.teachers.push(teacher);
        });
      });

    this.school.ref
      .collection("students-" + this.school.currentYear)
      .orderBy("firstName", "asc")
      .where("STUDENT_GROUP_ID", "==", this._getMyClassInfo("STUDENT_GROUP_ID"))
      .onSnapshot((querySnapshot) => {
        this.students = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          counter++;
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          item.index = counter;
          if (!item.moved) this.students.push(item);
        });
      });
  },
  methods: {
    _print3() {
      console.log(this.currentSelectedLesson);
    },
    async getLessonScoreAnalytics(item) {
      await this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(String(item.id))
        .collection("scores")
        .onSnapshot((docs) => {
          var approvedNumber = 0;
          var inProgressNumber = 0;
          var rejectedNumber = 0;
          var sentNumber = 0;
          var scoreAverage = 0;
          docs.forEach((doc) => {
            let score = doc.data();
            score.ref = doc.ref;
            score.id = doc.id;
            if (item.esisLessonType.esisLessonTypeId > 1) {
              if (
                score.STUDENT_GROUP_ID ==
                  this.selectedProgram.STUDENT_GROUP_ID &&
                score.id.includes("-" + score.lessonGroupId)
              ) {
                if (score.approved == true) {
                  approvedNumber++;
                  scoreAverage = scoreAverage + score.score;
                } else if (score.approved == false) {
                  rejectedNumber++;
                } else if (
                  (score.approved == undefined || score.approved == null) &&
                  score.sentToManager == true
                ) {
                  inProgressNumber++;
                }
                if (
                  score.sentToManager != true &&
                  score.score != null &&
                  score.score != undefined &&
                  typeof score.score == "number"
                ) {
                  sentNumber++;
                }
              }
            } else {
              if (
                score.STUDENT_GROUP_ID == this.selectedProgram.STUDENT_GROUP_ID
              ) {
                if (score.approved == true) {
                  approvedNumber++;
                  scoreAverage = scoreAverage + score.score;
                } else if (score.approved == false) {
                  rejectedNumber++;
                } else if (
                  (score.approved == undefined || score.approved == null) &&
                  score.sentToManager == true
                ) {
                  inProgressNumber++;
                }
                if (
                  score.sentToManager != true &&
                  score.score != null &&
                  score.score != undefined &&
                  typeof score.score == "number"
                ) {
                  sentNumber++;
                }
              }
            }
          });
          scoreAverage = (scoreAverage / approvedNumber).toFixed(2);

          var batch = fb.db.batch();

          var year1UnelgeeScores = {
            approvedNumber: approvedNumber,
            inProgressNumber: inProgressNumber,
            rejectedNumber: rejectedNumber,
            sentNumber: sentNumber,
            scoreAverage: scoreAverage,
          };

          batch.update(item.ref, {
            year1UnelgeeScores: year1UnelgeeScores,
          });
          item.year1UnelgeeScores = year1UnelgeeScores;
          this.$forceUpdate();

          setTimeout(() => {
            batch.commit().then(() => {
              console.log("asdasd");
            });
          }, 5000);
        });
    },
    async getClassStudentNumber(item) {
      setTimeout(() => {
        if (
          item &&
          item.esisLessonType &&
          item.esisLessonType.esisLessonTypeId == 1
        ) {
          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where(
              "classGroup-" + this.userData.school.currentYear,
              "==",
              item.classGroupRefs[0]
            )
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              if (
                item.numberOfStudents == undefined ||
                item.numberOfStudents != docs.size
              ) {
                item.numberOfStudents = docs.size;
                this.$forceUpdate();

                setTimeout(() => {
                  item.ref.update({ numberOfStudents: docs.size });
                }, 5000);
              }
            });
        } else if (
          item.esisLessonType &&
          item.esisLessonType.esisLessonTypeId > 1 &&
          item.subLessons &&
          item.subLessons.length > 0
        ) {
          // console.log(item.courseInfo.COURSE_NAME, item);
          var numberOfStudents = 0;
          for (const sll of item.subLessons) {
            sll.ref
              .collection("lesson-groups")
              .get()
              .then((docs) => {
                docs.forEach(async (doc) => {
                  await doc.ref
                    .collection("students")
                    .orderBy("FIRST_NAME", "asc")
                    .get()
                    .then((docs) => {
                      numberOfStudents = numberOfStudents + docs.size;
                    });
                });
              });
          }

          setTimeout(() => {
            if (
              item.numberOfStudents == undefined ||
              item.numberOfStudents != numberOfStudents
            ) {
              item.numberOfStudents = numberOfStudents;
              this.$forceUpdate();

              setTimeout(() => {
                item.ref.update({ numberOfStudents: numberOfStudents });
              }, 4000);
            }
          }, 1000);
        } else {
          var numberOfStudents2 = 0;

          item.ref
            .collection("lesson-groups")
            .get()
            .then((docs) => {
              docs.forEach(async (doc) => {
                if (doc.data().allStudentsSelected) {
                  this.userData.school.ref
                    .collection("students-" + this.userData.school.currentYear)
                    .where(
                      "classGroup-" + this.userData.school.currentYear,
                      "==",
                      item.classGroupRefs[0]
                    )
                    .orderBy("FIRST_NAME", "asc")
                    .get()
                    .then((docs) => {
                      numberOfStudents2 = numberOfStudents2 + docs.size;
                    });
                } else {
                  await doc.ref
                    .collection("students")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        if (
                          doc.data().STUDENT_GROUP_ID ==
                          this.selectedProgram.STUDENT_GROUP_ID
                        ) {
                          numberOfStudents2++;
                        }
                      });
                    });
                }
              });
              setTimeout(() => {
                if (
                  item.numberOfStudents == undefined ||
                  item.numberOfStudents != numberOfStudents2
                ) {
                  item.numberOfStudents = numberOfStudents2;
                  this.$forceUpdate();

                  setTimeout(() => {
                    item.ref.update({ numberOfStudents: numberOfStudents2 });
                  }, 1000);
                }
              }, 4000);
            });
        }
      }, 1000);
    },
    async _doUnelgee(item) {
      console.log(item);
      this.currentSelectedLesson = item;
      await this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(String(this.currentSelectedLesson.id))
        .collection("scores")
        .where("sentToManager", "==", true)
        .orderBy("createdAt", "asc")
        .get()
        .then((docs) => {
          this.allScores = [];
          docs.forEach(async (doc) => {
            let score = doc.data();
            score.ref = doc.ref;
            score.id = doc.id;
            await this.userData.school.ref
              .collection("students-" + this.userData.school.currentYear)
              .doc(String(score.PERSON_ID))
              .get()
              .then((doc) => {
                let studentData = doc.data();
                studentData.ref = doc.ref;
                studentData.id = doc.id;
                score.studentData = studentData;
              });
            this.allScores.push(score);
          });
          console.log(this.allScores);
        });

      if (this.currentSelectedLesson) {
        if (
          this.currentSelectedLesson.esisLessonType &&
          this.currentSelectedLesson.esisLessonType.esisLessonTypeId == 1
        ) {
          this.loading = true;

          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where(
              "classGroup-" + this.userData.school.currentYear,
              "==",
              this.currentSelectedLesson.classGroupRefs[0]
            )
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              this.students = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;
                let unelgee2 = this.allScores.find(
                  (sss) => sss.PERSON_ID == student.PERSON_ID
                );
                if (unelgee2) {
                  student.score =
                    unelgee2.score != null &&
                    unelgee2.score != undefined &&
                    typeof unelgee2.score == "number"
                      ? unelgee2.score
                      : null;
                  student.scoreFinal = unelgee2.scoreFinal
                    ? unelgee2.scoreFinal
                    : null;
                  student.scoreLevelString = unelgee2.scoreLevelString
                    ? unelgee2.scoreLevelString
                    : null;
                  student.scoreLevelNumber = unelgee2.scoreLevelNumber
                    ? unelgee2.scoreLevelNumber
                    : null;
                }

                if (!student.moved) {
                  this.students.push(student);
                }
              });
              this.loading = false;
              this.forceRerender();
              this.unelgeeDialog = !this.unelgeeDialog;
            });
        } else if (
          this.currentSelectedLesson.esisLessonType &&
          this.currentSelectedLesson.esisLessonType.esisLessonTypeId > 1 &&
          this.currentSelectedLesson.subLessons &&
          this.currentSelectedLesson.subLessons.length > 0
        ) {
          this.students = [];
          for (const sll of this.currentSelectedLesson.subLessons) {
            this.loading = true;
            sll.ref
              .collection("lesson-groups")
              .get()
              .then((docs) => {
                console.log(docs.size, this.selectedProgram);
                docs.forEach(async (doc) => {
                  let gg = doc.data();
                  gg.id = doc.id;
                  gg.ref = doc.ref;
                  gg.students = null;
                  gg.students = [];

                  await gg.ref
                    .collection("students")
                    .orderBy("FIRST_NAME", "asc")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        let student = doc.data();
                        student.id = doc.id;
                        student.ref = doc.ref;

                        if (
                          this.selectedProgram.STUDENT_GROUP_ID ==
                          student.STUDENT_GROUP_ID
                        ) {
                          student.classGroup = sll.classGroups[0];
                          student.classGroupRef =
                            sll.classGroups[0].classGroupRef;
                          student.lessonGroup = gg;
                          student.attendances = null;
                          student.studentsNotes = null;

                          let unelgee2 = this.allScores.find(
                            (sss) => sss.PERSON_ID == student.PERSON_ID
                          );
                          if (unelgee2) {
                            student.score =
                              unelgee2.score != null &&
                              unelgee2.score != undefined &&
                              typeof unelgee2.score == "number"
                                ? unelgee2.score
                                : null;
                            student.scoreFinal = unelgee2.scoreFinal
                              ? unelgee2.scoreFinal
                              : null;
                            student.scoreLevelString = unelgee2.scoreLevelString
                              ? unelgee2.scoreLevelString
                              : null;
                            student.scoreLevelNumber = unelgee2.scoreLevelNumber
                              ? unelgee2.scoreLevelNumber
                              : null;
                          }

                          gg.students.push(student);
                          // if (
                          //   this.students.find(
                          //     (stud) => stud.PERSON_ID == student.PERSON_ID
                          //   ) == undefined
                          // ) {
                          this.students.push(student);
                          // }
                        }
                      });
                    });
                });
              });
          }
          this.loading = false;
          this.forceRerender();
          this.unelgeeDialog = !this.unelgeeDialog;
        } else {
          this.loading = true;
          this.currentSelectedLesson.lessonGroups = null;
          this.students = [];
          this.currentSelectedLesson.isGroupBased = true;
          this.currentSelectedLesson.ref
            .collection("lesson-groups")
            .get()
            .then((docs) => {
              this.currentSelectedLesson.lessonGroups = [];
              docs.forEach(async (doc) => {
                let gg = doc.data();
                gg.id = doc.id;
                gg.ref = doc.ref;
                gg.students = [];

                if (gg.allStudentsSelected) {
                  this.userData.school.ref
                    .collection("students-" + this.userData.school.currentYear)
                    .where(
                      "classGroup-" + this.userData.school.currentYear,
                      "==",
                      this.currentSelectedLesson.classGroupRefs[0]
                    )
                    .orderBy("FIRST_NAME", "asc")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        let student = doc.data();
                        student.ref = doc.ref;
                        student.id = doc.id;
                        student.lessonGroup = gg;

                        let unelgee2 = this.allScores.find(
                          (sss) => sss.PERSON_ID == student.PERSON_ID
                        );
                        if (unelgee2) {
                          student.score =
                            unelgee2.score != null &&
                            unelgee2.score != undefined &&
                            typeof unelgee2.score == "number"
                              ? unelgee2.score
                              : null;
                          student.scoreFinal = unelgee2.scoreFinal
                            ? unelgee2.scoreFinal
                            : null;
                          student.scoreLevelString = unelgee2.scoreLevelString
                            ? unelgee2.scoreLevelString
                            : null;
                          student.scoreLevelNumber = unelgee2.scoreLevelNumber
                            ? unelgee2.scoreLevelNumber
                            : null;
                        }

                        // if (
                        //   this.students.find(
                        //     (stud) => stud.PERSON_ID == student.PERSON_ID
                        //   ) == undefined
                        // ) {
                        if (!student.moved) {
                          gg.students.push(student);
                          this.students.push(student);
                        }
                        // }
                      });
                    });
                } else {
                  var zz = await gg.ref.collection("students").get();
                  zz.docs.forEach((doc) => {
                    let student = doc.data();
                    student.ref = doc.ref;
                    student.id = doc.id;
                    if (
                      this.selectedProgram.STUDENT_GROUP_ID ==
                      student.STUDENT_GROUP_ID
                    ) {
                      student.lessonGroup = gg;

                      let unelgee2 = this.allScores.find(
                        (sss) => sss.PERSON_ID == student.PERSON_ID
                      );
                      if (unelgee2) {
                        student.score =
                          unelgee2.score != null &&
                          unelgee2.score != undefined &&
                          typeof unelgee2.score == "number"
                            ? unelgee2.score
                            : null;
                        student.scoreFinal = unelgee2.scoreFinal
                          ? unelgee2.scoreFinal
                          : null;
                        student.scoreLevelString = unelgee2.scoreLevelString
                          ? unelgee2.scoreLevelString
                          : null;
                        student.scoreLevelNumber = unelgee2.scoreLevelNumber
                          ? unelgee2.scoreLevelNumber
                          : null;
                      }

                      gg.students.push(student);
                      // if (
                      //   this.students.find(
                      //     (stud) => stud.PERSON_ID == student.PERSON_ID
                      //   ) == undefined
                      // ) {
                      this.students.push(student);
                      // }
                    }
                  });
                }

                this.currentSelectedLesson.lessonGroups.push(gg);
              });
              console.log(this.students);
              this.loading = false;
              this.forceRerender();
              this.unelgeeDialog = !this.unelgeeDialog;
            });
        }
      }
    },

    _saveUnelgee1OfStudents() {
      var batch = fb.db.batch();
      //var batchStudent = fb.db.batch()
      this.savingUnelgee = true;
      for (var score of this.allScores) {
        score["approvedAt"] = new Date();
        score["approvedByRef"] = this.userData.ref;
        score["approvedByEmail"] = this.userData.email;
        if (score.approved == false) {
          score["rejected"] = true;
        }

        batch.update(score.ref, score);
      }
      batch.commit().then(() => {
        this.savingUnelgee = false;
        this._closeUnelgeeDialog();
        this.$swal.fire("Амжилттай xадгалагдсан!");
      });
    },
    _closeUnelgeeDialog() {
      this.unelgeeDialog = !this.unelgeeDialog;
    },
    _print2() {
      console.log(this.selectedProgram.ref.path);
    },
    readPlan() {
      if (this.selectedProgram) {
        this.courses = null;
        this.selectedPlan.ref
          .collection("courses")
          .orderBy("COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            this.courses = [];
            docs.forEach((course) => {
              let sCourse = course.data();
              sCourse.ref = course.ref;
              sCourse.id = course.id;
              sCourse.eeljitLesson = null;
              this.school.ref
                .collection("lessons-" + this.school.currentYear)
                .doc(this.selectedProgram.id + "-" + sCourse["COURSE_ID"])
                .get()
                .then(async (doc) => {
                  if (doc.exists) {
                    sCourse.eeljitLesson = doc.data();
                    sCourse.eeljitLesson.id = doc.id;
                    sCourse.eeljitLesson.ref = doc.ref;
                    sCourse.eeljitLesson.lessonGroups = null;
                    sCourse.eeljitLesson.ref
                      .collection("lesson-groups")
                      .orderBy("groupIndex")
                      .onSnapshot(async (docs) => {
                        sCourse.eeljitLesson.lessonGroups = [];
                        docs.forEach(async (lgroup) => {
                          let ll = lgroup.data();
                          ll.id = lgroup.id;
                          ll.ref = lgroup.ref;
                          ll.ref
                            .collection("students")
                            .orderBy("FIRST_NAME", "asc")
                            .onSnapshot((docs) => {
                              ll.students = [];
                              var counter = 0;
                              docs.forEach((doc) => {
                                counter++;
                                let gstudent = doc.data();
                                gstudent.id = doc.id;
                                gstudent.ref = doc.ref;
                                gstudent.index = counter;
                                ll.students.push(gstudent);
                              });

                              this.$forceUpdate();
                            });

                          sCourse.eeljitLesson.lessonGroups.push(ll);
                        });
                        await this.getClassStudentNumber(sCourse.eeljitLesson);
                        await this.getLessonScoreAnalytics(
                          sCourse.eeljitLesson
                        );
                      });
                  }
                });
              this.courses.push(sCourse);
            });
            this.courses = this.courses.sort(
              (a, b) =>
                b.ENROLLMENT_CATEGORY.length - a.ENROLLMENT_CATEGORY.length
            );
          });
      }
    },
    readAdditionalLessons() {
      if (this.selectedProgram) {
        this.coursesAdditional = null;
        this.school.ref
          .collection("lessons-" + this.school.currentYear)
          .where("isAdditional", "==", true)
          .where("programId", "==", this.selectedProgram.id)
          .onSnapshot((docs) => {
            this.coursesAdditional = [];
            docs.forEach((doc) => {
              let aLesson = doc.data();
              let sCourse = aLesson.courseInfo;
              sCourse.ref = aLesson.courseInfo.ref;
              sCourse.id = aLesson.courseInfo.ref.id;
              // sCourse.courseIndex = courseIndex;
              sCourse.eeljitLesson = aLesson;
              sCourse.eeljitLesson.id = doc.id;
              sCourse.eeljitLesson.ref = doc.ref;
              sCourse.eeljitLesson.lessonGroups = null;
              sCourse.eeljitLesson.ref
                .collection("lesson-groups")
                .orderBy("groupIndex")
                .onSnapshot((docs) => {
                  sCourse.eeljitLesson.lessonGroups = [];
                  docs.forEach((lgroup) => {
                    let ll = lgroup.data();
                    ll.id = lgroup.id;
                    ll.ref = lgroup.ref;
                    // this.$forceUpdate();
                    ll.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .onSnapshot((docs) => {
                        ll.students = [];
                        var counter = 0;
                        docs.forEach((doc) => {
                          counter++;
                          let gstudent = doc.data();
                          gstudent.id = doc.id;
                          gstudent.ref = doc.ref;
                          gstudent.index = counter;

                          this.$forceUpdate();
                          ll.students.push(gstudent);
                        });
                      });
                    sCourse.eeljitLesson.lessonGroups.push(ll);
                  });
                });
              this.coursesAdditional.push(sCourse);
            });
          });
      }
    },
    _print(item) {
      console.log(item, item.eeljitLesson.ref.path);
      console.log(this.students.length);
    },
    _getMyClassInfo(value) {
      if (this.zClassGroup) return this.zClassGroup[value];
      else return null;
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
